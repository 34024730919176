import { combineReducers } from "@reduxjs/toolkit";
import { reducer as channelConfigReducer } from "@/slices/channel-config";
import { reducer as stepperReducer } from "@/slices/stepper";
import { reducer as authenticationReducer } from "@/slices/authentication";
import { reducer as inductionReducer } from "@/slices/induction";

export const rootReducer = combineReducers({
  channelConfig: channelConfigReducer,
  stepper: stepperReducer,
  authentication: authenticationReducer,
  induction: inductionReducer,
});
