/* deepscan-disable UNUSED_VAR_ASSIGN */
import { CHANNEL_CONFIG } from "@/lib/api/interface";
import { createSlice } from "@reduxjs/toolkit";

export type ChannelConfigState = Omit<CHANNEL_CONFIG, "serverSideOnlyParams">;

export const initialState = {} as ChannelConfigState;

export const slice = createSlice({
  name: "channel-config",
  initialState,
  reducers: {},
});

export const { reducer } = slice;

export const channelConfig = {
  ...slice.actions,
};
