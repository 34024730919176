import { CHANNEL_CONFIG } from "@/lib/api/interface";
import { GTM_ID, pageview } from "@/lib/gtm";
import { createStore, RootState } from "@/store";
import {
  intlRichTextElements,
  KenaiApp,
  type KenaiAppProps,
} from "@kenai/core";
import Head from "next/head";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import "../styles/global.css";

const getInitialStore = (channelConfig: CHANNEL_CONFIG = {} as any) => {
  const processingLocations = channelConfig.locations?.filter((location) => {
    return channelConfig.mappedLocationHierarchy !== location.EntityHierarchy;
  });
  const isSingleTenant = processingLocations?.length === 1;

  return {
    channelConfig,
    ...(isSingleTenant && {
      authentication: {
        selectedHierarchy: processingLocations[0].EntityHierarchy,
      },
    }),
  };
};

const InductionApp: FC<KenaiAppProps> = (props) => {
  const { events } = useRouter();

  const pageProps = props.pageProps ?? {};
  const channelConfig = (pageProps?.channelConfig ?? {}) as CHANNEL_CONFIG;
  const store = createStore(getInitialStore(channelConfig) as RootState);

  const { defaultLocale = "en", locale = "en" } = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={pageProps.intlMessages || {}}
      defaultRichTextElements={intlRichTextElements}
    >
      <ReduxProvider store={store}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <KenaiApp title="Employee Induction - Kenai" gtm={GTM_ID} {...props} />
      </ReduxProvider>
    </IntlProvider>
  );
};

export default InductionApp;
