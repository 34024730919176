import type { Action } from "@reduxjs/toolkit";
import {
  configureStore as createStore,
  createImmutableStateInvariantMiddleware,
  isRejectedWithValue,
  type ConfigureStoreOptions,
  type Middleware,
  type MiddlewareAPI,
} from "@reduxjs/toolkit";
import { type ThunkMiddlewareFor } from "@reduxjs/toolkit/src/getDefaultMiddleware";
import { type AnyAction } from "redux";
import logger from "redux-logger";

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: [],
});

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      // TODO add sentry logging here
    }

    return next(action);
  };

const showDevTools =
  process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true" ||
  process.env.NODE_ENV === "development";

declare type Middlewares<S> = ReadonlyArray<Middleware<{}, S>>;

export const configureStore = <
  S = any,
  A extends Action = AnyAction,
  M extends Middlewares<S> = [ThunkMiddlewareFor<S>]
>(
  options: ConfigureStoreOptions<S, A, M>
) => {
  if (options.middleware) {
    throw new Error(
      "Middleware option is still not supported, it should be implemented with the base middleware config."
    );
  }

  return createStore({
    ...options,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(rtkQueryErrorLogger)
        .concat(logger)
        .concat(immutableInvariantMiddleware);
    },
    devTools: showDevTools,
  });
};
