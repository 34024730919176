/* deepscan-disable UNUSED_VAR_ASSIGN */
import { InferResponse } from "@/lib/api";
import { type VALIDATION_FAILURES } from "@/lib/api/enums";
import { INDUCTION_DATA } from "@/lib/api/interface";
import { RootState } from "@/store";
import { createEventTiming, fetch } from "@kenai/utils";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { validateUserDetails } from "./authentication";

type ValidateUserDetailsResponse = Awaited<
  InferResponse<"validateUserDetails">
>;
type Response = InferResponse<"submitInduction">;

export const submitInduction = createAsyncThunk<Response>(
  "/induction/submit",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const response = await fetch<Response>("/api/submit", {
        method: "POST",
        body: JSON.stringify({
          processingToken: state.authentication.processingToken,
          eventTiming: createEventTiming(),
          version: state.induction.inductionData.version,
        }),
      });
      if (response.key !== "OPERATION_PROCESSED") {
        if (response.processingResponse?.validationFailure) {
          return response;
        }
        throw response.error;
      }
      return response;
    } catch (error: any) {
      const message =
        error?.message ??
        "We ran into an unexpected error, please contact the support team if this persists.";
      throw new Error(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { induction } = getState() as RootState;
      if (!["idle", "failure"].includes(induction.loading)) {
        // Already processed or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const initialState = {
  inductionData: {
    inductionVideoLink: "",
    version: 0,
    inductionQuestions: [],
  } as INDUCTION_DATA,
  loading: "idle" as "idle" | "pending" | "success" | "failure",
  validationFailure: "" as VALIDATION_FAILURES,
  error: null as string | null,
};

export const slice = createSlice({
  name: "induction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        validateUserDetails.fulfilled,
        (state, action: PayloadAction<ValidateUserDetailsResponse>) => {
          if (
            !action.payload?.processingResponse?.validationFailure &&
            action.payload?.processingResponse?.inductionData
          ) {
            state.inductionData =
              action.payload?.processingResponse?.inductionData;
          }
        }
      )
      .addCase(submitInduction.pending, (state) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addCase(
        submitInduction.fulfilled,
        (state, action: PayloadAction<Response>) => {
          if (state.loading === "pending") {
            if (action.payload?.key === "OPERATION_PROCESSED") {
              state.loading = "success";
            } else if (action.payload?.processingResponse?.validationFailure) {
              state.loading = "failure";
              state.validationFailure =
                action.payload?.processingResponse?.validationFailure;
            } else {
              state.loading = "idle";
            }
          }
        }
      )
      .addCase(submitInduction.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "failure";
          state.error = action.error?.message ?? "An unknown error occurred.";
        }
      });
  },
});

export const { reducer } = slice;

export const channelConfig = {
  ...slice.actions,
};
