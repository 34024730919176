import { configureStore } from "@kenai/core";
import type { Action } from "@reduxjs/toolkit";

import type { TypedUseSelectorHook } from "react-redux";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { ThunkAction } from "redux-thunk";
import { rootReducer } from "./root-reducer";

export const createStore = (preloadedState = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();

/**
 * We use this when we create a middleware thunk, because dispatch wants a type.
 * @see https://github.com/reduxjs/redux-toolkit/issues/321
 */
export const createThunk = (thunk: AppThunk, type?: string) => {
  const thunkWithType = thunk as AppThunk & { type: string };
  thunkWithType.type = type ?? "middleware-thunk";
  return thunkWithType;
};
