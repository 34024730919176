/* deepscan-disable UNUSED_VAR_ASSIGN */
import { CHANNEL_CONFIG } from "@/lib/api/interface";
import { tuple } from "@kenai/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ChannelConfigState = Omit<CHANNEL_CONFIG, "serverSideOnlyParams">;

export const Steps = tuple(
  "agreement",
  "authentication",
  "induction",
  "summary"
);
export type Steps = (typeof Steps)[number];

export const initialState = {
  currentStep: "agreement" as Steps,
  steps: Steps as Steps[],
};

export const slice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    nextStep: (state: typeof initialState) => {
      const currentIndex = state.steps.indexOf(state.currentStep);
      const nextStep = Steps[currentIndex + 1];
      if (!nextStep) {
        console.warn("No next step found");
      }
      state.currentStep = nextStep || state.currentStep;
    },
    previousStep: (state: typeof initialState) => {
      const currentIndex = state.steps.indexOf(state.currentStep);
      const nextStep = Steps[currentIndex - 1];
      if (!nextStep) {
        console.warn("No previous step found");
      }
      state.currentStep = nextStep || state.currentStep;
    },
    setStep(state: typeof initialState, action: PayloadAction<Steps>) {
      state.currentStep = action.payload;
    },
    setActiveSteps(state: typeof initialState, action: PayloadAction<Steps[]>) {
      state.steps = action.payload.sort(
        (a, b) => Steps.indexOf(a) - Steps.indexOf(b) // ensure the correct order
      );
    },
  },
});

export const { reducer } = slice;

export const stepper = {
  ...slice.actions,
};
