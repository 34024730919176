/* deepscan-disable UNUSED_VAR_ASSIGN */
import { InferResponse } from "@/lib/api";
import { RootState } from "@/store";
import { fetch } from "@kenai/utils";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

type Response = Awaited<InferResponse<"validateUserDetails">>;

export const validateUserDetails = createAsyncThunk<
  any,
  {
    EntityHierarchy: string;
    organizationIdValue: string;
    idFieldValue: string;
    useAlternatePersonalId: boolean;
  }
>(
  "/authentication/validateUserDetails",
  async (user, _thunkAPI) => {
    try {
      const response = await fetch<InferResponse<"validateUserDetails">>(
        "/api/auth/identifier",
        {
          method: "POST",
          body: JSON.stringify(user),
        }
      );
      if (response.key !== "OPERATION_PROCESSED") {
        if (response.processingResponse?.validationFailure) {
          return response;
        }
        throw response.error;
      }
      return response;
    } catch (error: any) {
      const message =
        error?.message ??
        "We ran into an unexpected error, please contact the support team if this persists.";
      throw new Error(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { authentication } = getState() as RootState;
      if (
        authentication.processingToken ||
        authentication.loading === "pending"
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const initialState = {
  selectedHierarchy: "",
  processingToken: "",
  error: null as string | null,
  validationFailure: null as
    | NonNullable<Response["processingResponse"]>["validationFailure"]
    | null,
  loading: "idle" as "idle" | "pending" | "error",
};

export const slice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setSelectedHierarchy(state, action: PayloadAction<string>) {
      state.selectedHierarchy = action.payload;
      state.processingToken = "";
      if (state.loading !== "idle") {
        state.loading = "idle";
      }
    },
    resetError(state) {
      state.loading = "idle";
      state.validationFailure = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateUserDetails.pending, (state) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.processingToken = "";
        }
      })
      .addCase(
        validateUserDetails.fulfilled,
        (state, action: PayloadAction<Response>) => {
          if (state.loading === "pending") {
            state.loading = "idle";
            if (action.payload?.processingResponse?.validationFailure) {
              state.validationFailure =
                action.payload?.processingResponse?.validationFailure;
            } else if (action.payload?.processingResponse?.processingToken) {
              state.processingToken =
                action.payload?.processingResponse?.processingToken;
            }
          }
        }
      )
      .addCase(validateUserDetails.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "error";
          state.error = action.error?.message ?? "An error occurred";
        }
      });
  },
});

export const { reducer } = slice;

export const authentication = {
  ...slice.actions,
  validateUserDetails,
};
